const managerUrl = "/api"; // 认证层接口地址 正式环境

const api = {
  public: {
    getArea: `${managerUrl}/system/area/children`, // 获取地区列表
  },
  system: {
    loginCode: `${managerUrl}/mgr/user/login/yzm`, // 验证码登录
    getCode: `${managerUrl}/mgr/user/login/getyzm`, // 获取验证码
    loginPwd: `${managerUrl}/mgr/user/login/pwd`, // 密码登录
    loginOut: `${managerUrl}/manager/user/login/out`, // 退出登录
    getRoles: `${managerUrl}/mgr/user/menus`, //获取权限列表
    userInfo: `${managerUrl}/mgr/user/info`, //用户信息
    updateUserAvatar: `${managerUrl}/mgr/user/upava`, //修改用户头像
    updateUserPwd: `${managerUrl}/mgr/user/uppwd`, // 修改用户密码
    areaGcj02: `${managerUrl}/system/area/gcj02`, // 地区定位接口
    userInfoStatis: `${managerUrl}/user/info/statis`, // 会员统计接口 new
    userInfoMgrUser: `${managerUrl}/user/info/mgrUser`, // 用户信息操作
    getShareInfoEwm: `${managerUrl}/share/info/ewm`, // 获取小程序码
    oss: {
      pmmUserAvatarImg: `${managerUrl}/system/oss/policy?business=xmdUserAvatarImg`, // 用户头像
      pmmUserIdentityImg: `${managerUrl}/system/oss/policy?business=xmdUserIdentityImg`, // 身份证
      pmmUserCertImg: `${managerUrl}/system/oss/policy?business=xmdUserCertImg`, // 资质证书
      pmmUserVideoImg: `${managerUrl}/system/oss/policy?business=xmdUserVideoImg`, // 视频封面
      pmmUserVideoVideo: `${managerUrl}/system/oss/policy?business=xmdUserVideoVideo`, // 用户视频
      pmmUserWorkImg: `${managerUrl}/system/oss/policy?business=xmdUserWorkImg`, // 工作照
      pmmShopLicenseImg: `${managerUrl}/system/oss/policy?business=xmdShopLicenseImg`, // 工作照
      pmmStoreAvatarImg: `${managerUrl}/system/oss/policy?business=xmdStoreAvatarImg`, // 门店头像
      pmmStorePicImg: `${managerUrl}/system/oss/policy?business=xmdStorePicImg`, // 门店照片
      pmmStoreVideoVideo: `${managerUrl}/system/oss/policy?business=xmdStoreVideoVideo`, // 门店视频
      pmmSystemBannerImg: `${managerUrl}/system/oss/policy?business=xmdSystemBannerImg`, // 系统横幅
      pmmMallGoodsImg: `${managerUrl}/system/oss/policy?business=xmdMallGoodsImg`, // 商城商品图片
      pmmMallGoodsVideo: `${managerUrl}/system/oss/policy?business=xmdMallGoodsVideo`, // 商城商品视频
    },
  },
  // 项目套餐
  project: {
    infoList: `${managerUrl}/pro/info/list`, // 项目套餐列表
    infoUnlist: `${managerUrl}/pro/info/unlist`, // 项目待审核列表
    infoExamine: `${managerUrl}/pro/info/examine`, // 项目审核
    projectType: `${managerUrl}/pro/type/children`, // 项目类别
    infoMgr: `${managerUrl}/pro/info/mgr`, // 项目禁用启用
    infoLast: `${managerUrl}/pro/info/last`, // 项目最新信息
    infoQuery: `${managerUrl}/pro/info/query`, // 项目信息
    infoSetMgr: `${managerUrl}/pro/info/setMgr`, // 项目禁用启用
  },
  // 平台管理
  platform: {
    bannerPlaces: `${managerUrl}/system/banner/places`, // 横幅位置
    bannerList: `${managerUrl}/system/banner/mgrlist`, // 横幅列表
    bannerMgr: `${managerUrl}/system/banner/mgropt`, // 横幅启用禁用
    bannerAdd: `${managerUrl}/system/banner/add`, // 横幅新增
    bannerUpdate: `${managerUrl}/system/banner/update`, // 横幅修改
    wordsList: `${managerUrl}/search/words/mgrlist`, // 热搜词列表
    wordsMgr: `${managerUrl}/search/words/mgropt`, // 热搜词启用禁用
    wordsAdd: `${managerUrl}/search/words/add`, // 热搜词新增
    wordsUpdate: `${managerUrl}/search/words/update`, // 热搜词修改
  },
  // 资金管理
  fund: {
    refundList: `${managerUrl}/money/wallet/refund/list`, // 退款申请列表
    refundMgr: `${managerUrl}/money/wallet/refund/mgr`, // 退款通过拒绝
    walletList: `${managerUrl}/money/wallet/list`, // 钱包明细列表
    withdrawList: `${managerUrl}/account/withdraw/list`, // 提现列表
    withdrawMgr: `${managerUrl}/account/withdraw/mgr`, // 提现操作
    infoList: `${managerUrl}/account/info/list`, // 账户明细列表
  },
  //商品管理
  goods: {
    typeBlist: `${managerUrl}/goods/type/blist`, // 业务分类列表
    typeGlist: `${managerUrl}/goods/type/mgrlist`, // 商品分类列表 new
    typeAdd: `${managerUrl}/goods/type/add`, // 商品分类新增 new
    typeDetail: `${managerUrl}/goods/type/detail`, // 商品分类详情 new
    typeUpdate: `${managerUrl}/goods/type/update`, // 商品分类修改 new
    infoList: `${managerUrl}/goods/info/mgrlist`, // 商品信息列表 new
    infoAdd: `${managerUrl}/goods/info/add`, // 商品信息新增 new
    infoQuery: `${managerUrl}/goods/info/mgrdetail`, // 商品信息详情 new
    infoUpdate: `${managerUrl}/goods/info/update`, // 商品信息修改 new
    saleList: `${managerUrl}/goods/sale/mgrlist`, // 商品销售列表 new
    saleAdd: `${managerUrl}/goods/sale/add`, // 商品销售新增 new
    saleDetail: `${managerUrl}/goods/sale/mgrdetail`, // 商品销售详情 new
    saleUpdate: `${managerUrl}/goods/sale/update`, // 商品销售修改 new
    chaptersList: `${managerUrl}/goods/chapters/list`, // 课程章节列表
    chaptersAdd: `${managerUrl}/goods/chapters/add`, // 课程章节新增
    chaptersDetail: `${managerUrl}/goods/chapters/detail`, // 课程章节详情
    chaptersUpdate: `${managerUrl}/goods/chapters/update`, // 课程章节修改
    classesList: `${managerUrl}/goods/classes/list`, // 培训课程列表
    classesAdd: `${managerUrl}/goods/classes/add`, // 培训课程新增
    classesDetail: `${managerUrl}/goods/classes/detail`, // 培训课程详情
    classesUpdate: `${managerUrl}/goods/classes/update`, // 培训课程修改
    orderMgrlist: `${managerUrl}/goods/order/mgrlist`, // 商品订单列表 new
    orderMgrlistTs: `${managerUrl}/goods/order/mgrlist/ts`, // 特殊商品订单列表 new
    orderMgradd: `${managerUrl}/goods/order/mgradd`, // 商品订单添加 new
    orderMgrdetail: `${managerUrl}/goods/order/mgrinfo`, // 商品订单详情 new
    orderAllinfo: `${managerUrl}/goods/order/allinfo`, // 商品订单资料
    orderSend: `${managerUrl}/goods/order/send`, // 商品订单发货
    orderLogisticsList: `${managerUrl}/goods/order/logisticsList`, // 物流列表
    orderLogistics: `${managerUrl}/goods/order/logistics`, // 商品订单物流更新
    orderReturn: `${managerUrl}/goods/order/return`, // 商品订单退货
    orderArrival: `${managerUrl}/goods/order/arrival`, // 商品订单到货
    platformList: `${managerUrl}/goods/platform/list`, // 商品所属平台列表
    orderCancel: `${managerUrl}/goods/order/cancel`, // 商品订单取消
    orderFinish: `${managerUrl}/goods/order/finish`, // 商品订单完成
    orderDoit: `${managerUrl}/goods/order/doit`, // 商品订单处理
    orderCreate: `${managerUrl}/goods/order/create`, // 商品订单创建
    orderStatis: `${managerUrl}/goods/order/statis`, // 商品订单统计
    orderStatisTs: `${managerUrl}/goods/order/statis/ts`, // 特殊商品订单统计
  },
  // 订单管理
  order: {
    serverList: `${managerUrl}/order/server/list`, // 服务列表
    serverInfo: `${managerUrl}/order/server/info`, // 服务信息
  },
  // 用户管理
  user: {
    userList: `${managerUrl}/user/info/mgrlist`, // 用户列表
    userListTs: `${managerUrl}/user/info/mgrlist/ts`, // 特殊账号列表
    userInfo: `${managerUrl}/user/info/mgrinfo`, // 用户详情
    upMgr: `${managerUrl}/user/hhr/upmgr`, // 升级合伙人
    downMgr: `${managerUrl}/user/hhr/downmgr`, // 降级合伙人
    updl: `${managerUrl}/user/hhr/updl`, // 升级代理
    userInfoAddAccount: `${managerUrl}/user/info/addAccount`, // 新增特殊账号
    accountInfoAddMoney: `${managerUrl}/account/info/addMoney`, // 加钱，设置
  },
  // 客服管理
  customerService: {
    mgrList: `${managerUrl}/system/css/mgrlist`, // 客服列表
    add: `${managerUrl}/system/css/add`, // 客服新增
    update: `${managerUrl}/system/css/update`, // 客服修改
    mgr: `${managerUrl}/system/css/mgr`, // 客服操作

  },
  // 统计
  statistics: {
    goods: `${managerUrl}/income/statis/goods`, //商品统计接口
    homeIncome: `${managerUrl}/income/statis/mgr`, //首页收益统计
    homeUserInfoMgr: `${managerUrl}/user/info/mgr`, //首页会员统计
    homeIncomeGmv: `${managerUrl}/income/statis/gmv`, //首页销售统计
    homeIncomeLirun: `${managerUrl}/income/statis/lirun`, //首页利润统计
    incomeStatisGoods: `${managerUrl}/income/statis/goods`, //商品统计
  }
};

export default api;
