import Vue from "vue";
import VueRouter from "vue-router";
// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

// 常量路由 所有角色可见
export const constantRoutes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "*",
    name: "dashboard",
    component: () => import("@/views/dashboard"),
  },
];

// 异步路由 根据角色过滤可见
export const asyncRoutes = [
  //首页
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
  },
  //个人中心
  {
    path: "/center",
    name: "center",
    component: () => import("@/views/center"),
  },
  // 项目管理项目审核列表
  {
    path: "/project/audit",
    name: "projectAudit",
    component: () => import("@/views/project/audit"),
  },
  // 项目管理项目套餐审核详情
  {
    path: "/project/audit/detail",
    name: "projectAuditDetail",
    component: () => import("@/views/project/audit/detail"),
  },
  // 项目管理项目套餐列表
  {
    path: "/project/list",
    name: "projectList",
    component: () => import("@/views/project/list"),
  },
  // 项目管理项目套餐详情
  {
    path: "/project/list/detail",
    name: "projectListDetail",
    component: () => import("@/views/project/list/detail"),
  },
  // 优惠券
  {
    path: "/coupon/list",
    name: "couponList",
    component: () => import("@/views/coupon/list"),
  },
  // 平台管理横幅管理
  {
    path: "/platform/banner",
    name: "platformBanner",
    component: () => import("@/views/platform/banner"),
  },
  // 平台管理搜索词管理
  {
    path: "/platform/search",
    name: "platformSearch",
    component: () => import("@/views/platform/search"),
  },
  // 平台管理用户管理列表
  {
    path: "/platform/user",
    name: "platformUser",
    component: () => import("@/views/platform/user"),
  },
  // 平台管理用户管理新增特殊账号
  {
    path: "/platform/user/addSpecial",
    name: "platformUserAddSpecial",
    component: () => import("@/views/platform/user/addSpecial"),
  },
  // 平台管理用户管理详情
  {
    path: "/platform/user/detail",
    name: "platformUserDetail",
    component: () => import("@/views/platform/user/detail"),
  },
  // 平台管理客服管理
  {
    path: "/platform/customerService",
    name: "platformCustomerService",
    component: () => import("@/views/platform/customerService"),
  },
  // 资金管理退款申请
  {
    path: "/fund/refund",
    name: "fundRefund",
    component: () => import("@/views/fund/refund"),
  },
  // 资金管理钱包明细
  {
    path: "/fund/refund/wallet",
    name: "fundRefundWallet",
    component: () => import("@/views/fund/refund/wallet"),
  },
  // 资金管理提现申请
  {
    path: "/fund/withdraw",
    name: "fundWithdraw",
    component: () => import("@/views/fund/withdraw"),
  },
  // 资金管理账户明细
  {
    path: "/fund/withdraw/account",
    name: "fundWithdrawAccount",
    component: () => import("@/views/fund/withdraw/account"),
  },
  // 订单管理列表
  {
    path: "/order/list",
    name: "orderList",
    component: () => import("@/views/order/list"),
  },
  // 订单管理订单新增
  {
    path: "/order/list/add",
    name: "orderListAdd",
    component: () => import("@/views/order/list/add"),
  },
  // 商品管理商品分类
  {
    path: "/goods/type",
    name: "goodsType",
    component: () => import("@/views/goods/type"),
  },
  // 商品管理商品信息
  {
    path: "/goods/info",
    name: "goodsInfo",
    component: () => import("@/views/goods/info"),
  },
  // 商品管理商品信息商品新增
  {
    path: "/goods/info/add",
    name: "goodsInfoAdd",
    component: () => import("@/views/goods/info/add"),
  },
  // 商品管理商品信息商品详情
  {
    path: "/goods/info/detail",
    name: "goodsInfoDetail",
    component: () => import("@/views/goods/info/detail"),
  },
  // 商品管理商品信息商品详情
  {
    path: "/goods/info/update",
    name: "goodsInfoUpdate",
    component: () => import("@/views/goods/info/update"),
  },
  // 商品管理商品销售
  {
    path: "/goods/sale",
    name: "goodsSale",
    component: () => import("@/views/goods/sale"),
  },
  // 商品管理商品销售销售新增
  {
    path: "/goods/sale/add",
    name: "goodsSaleAdd",
    component: () => import("@/views/goods/sale/add"),
  },
  // 商品管理商品销售销售详情
  {
    path: "/goods/sale/detail",
    name: "goodsSaleDetail",
    component: () => import("@/views/goods/sale/detail"),
  },
  // 商品管理商品销售销售修改
  {
    path: "/goods/sale/update",
    name: "goodsSaleUpdate",
    component: () => import("@/views/goods/sale/update"),
  },
  // 商品管理培训章节
  {
    path: "/goods/chapter",
    name: "goodsChapter",
    component: () => import("@/views/goods/chapter"),
  },
  // 商品管理培训课程
  {
    path: "/goods/course",
    name: "goodsCourse",
    component: () => import("@/views/goods/course"),
  },
  // 商品管理商品订单
  {
    path: "/goods/order",
    name: "goodsOrder",
    component: () => import("@/views/goods/order"),
  },
  // 商品管理商品订单新增
  {
    path: "/goods/order/add",
    name: "goodsOrderAdd",
    component: () => import("@/views/goods/order/add"),
  },
  // 商品管理商品订单详情
  {
    path: "/goods/order/detail",
    name: "goodsOrderDetail",
    component: () => import("@/views/goods/order/detail"),
  },
  // // 商品管理商品订单
  // {
  //   path: "/goods/order",
  //   name: "goodsOrder",
  //   component: () => import("@/views/goods/order"),
  // },
  // 分类页面
  {
    path: "/platform/type",
    name: "platformType",
    component: () => import("@/views/platform/type"),
  },
  // {
  //   path: "/statistics/transaction",
  //   name: "statisticsTransaction",
  //   component: () => import("@/views/statistics/transaction"),
  // },
  {
    path: "/statistics/member",
    name: "statisticsMember",
    component: () => import("@/views/statistics/member"),
  },
  {
    path: "/statistics/goods",
    name: "statisticsGoods",
    component: () => import("@/views/statistics/goods"),
  },
  {
    path: "/special/user",
    name: "specialUser",
    component: () => import("@/views/special/user"),
  },
  {
    path: "/special/user/detail",
    name: "specialUser",
    component: () => import("@/views/special/user/detail"),
  },
  {
    path: "/special/user/addSpecial",
    name: "specialUser",
    component: () => import("@/views/special/user/addSpecial"),
  },
  {
    path:'/special/order',
    name: 'specialOrder',
    component: () => import('@/views/special/order')
  },
  {
    path:'/special/order/add',
    name: 'specialOrderAdd',
    component: () => import('@/views/special/order/add')
  }
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: "/",
    routes: constantRoutes,
  });

const router = createRouter();

// 提示路由
export function hintRouter() {
  router.addRoute("dashboard", {
    path: "/401",
    name: "401",
    component: () => import("@/views/401"),
  });
  router.addRoute("dashboard", {
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
  });
}

// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
